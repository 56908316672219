.hide-on-mobile {
  display: none;
}

.hide-on-desktop {
  display: block;
}

@media (min-width: 768px) {
  .hide-on-mobile {
    display: block;
  }

  .hide-on-desktop {
    display: none;
  }
}

.col-bg-img-one.ttm-col-bgimage-yes .ttm-col-wrapper-bg-layer {
  background-image: url('/images/why-question-mark-wider.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  opacity: 1;
}
